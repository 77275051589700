body, div {
  margin: 0;
  width: 100%;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.backgroundImage {
  /*background-image: url("./assets/hall-hd.jpg");*/
  background-size: cover;
}

.cropper {
  position: relative;
  min-height: 350px;
}

.Toastify__toast {
  overflow: hidden !important;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji !important;
  height: auto !important;
}

.Toastify__close-button {
  align-self: auto !important;
}

.Toastify__close-button--light {
  opacity: 1 !important;
}

.toast-message {
  font-size: 16px;
  font-weight: 500; 
  padding: 0px 0px;
  width: auto;
}